// react-router-dom components
import { Link, useParams, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useTranslation } from "react-i18next";
import { getCookie, STORAGEKEY } from "utils/storage";
import { useEffect } from "react";

function HandleRedirectGmail() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const auth = getCookie(STORAGEKEY.ACCESS_TOKEN);
  useEffect(() => {
    if (auth) {
      navigate(`/${params.page}/detail/${params.id}`);
    } else {
      if (params.page === "anken") {
        navigate("/login", {
          state: {
            redirect: `/${params.page}/detail/${params.id}`,
          },
        });
      }
      if (params.page === "accident") {
        navigate("/admin", {
          state: {
            redirect: `/${params.page}/detail/${params.id}`,
          },
        });
      }
    }
  }, []);
  return (
    <BasicLayout>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography variant="h4" color="text" align="center" sx={{ paddingBottom: "20px" }}>
            新しいパスワードを作成
          </MDTypography>
          <MDTypography variant="body2" color="text">
            パスワードリセットのリンクが無効/期限切れになっています。{" "}
            <MDTypography
              component={Link}
              to="/forgot-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              新しいパスワードリセット
            </MDTypography>{" "}
            をリクエストしてください。
          </MDTypography>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default HandleRedirectGmail;
