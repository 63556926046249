import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Paper } from "@mui/material";

function AccidentLayout({ children }) {
  return (
    <PageLayout>
      <DefaultNavbar
        action={{
          type: "external",
          route: "https://creative-tim.com/product/material-dashboard-react",
          label: "free download",
          color: "dark",
        }}
      />
      <MDBox
        px={1}
        width="100%"
        mx="auto"
        height="100vh"
        sx={{
          paddingTop: "168px",
          paddingBottom: "120px",
          overflow: "auto",
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={11} sm={11} md={11} lg={10} xl={8}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer light /> */}
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
AccidentLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccidentLayout;
