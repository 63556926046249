import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DatePickerController from "components/withController/DatePickerController";
import InputController from "components/withController/InputController";
import SelectController from "components/withController/SelectController";
import UploadFileController from "components/withController/UploadFileController";
import moment from "moment";
import { useTranslation } from "react-i18next";

function FormAccidentCars({
  control,
  watch,
  renderButtonAction,
  fileList,
  setFileList,
  isSubAdmin = false,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Card sx={{ marginTop: "60px" }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          {t("title.ca")}
        </MDTypography>
      </MDBox>

      <MDBox pt={4} pb={3} px={3}>
        <MDTypography variant="subtitle2" color="text" fontWeight="regular" sx={{ margin: "0px" }}>
          {t("desc.ca.1")}
        </MDTypography>
        <MDTypography variant="subtitle2" color="text" fontWeight="regular" sx={{ margin: "0px" }}>
          {t("desc.ca.2")}
        </MDTypography>
        <MDTypography variant="subtitle2" color="text" fontWeight="regular" sx={{ margin: "0px" }}>
          {t("desc.ca.3")}
        </MDTypography>

        <MDBox>
          <MDTypography variant="h6" sx={{ margin: "20px 0px 14px 0px" }}>
            {t("title.ca.1")}
          </MDTypography>
          <Grid container columnSpacing={3}>
            <InputController
              name="caDriverName"
              control={control}
              type="text"
              label={t("lable.caDriverName")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            {/* <SelectController
              name="caDriverType"
              control={control}
              label={t("lable.caDriverType")}
              option={[
                { id: 1, name: t("lable.caDriverType.1") },
                { id: 2, name: t("lable.caDriverType.2") },
              ]}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            /> */}
            <InputController
              name="caDriverContract"
              control={control}
              type="text"
              label={t("lable.caDriverContract")}
              isAutoFormatPhoneNumber
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />

            <DatePickerController
              name="caDriverBirthday"
              control={control}
              label={t("lable.caDriverBirthday")}
              maxDate={moment()}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <DatePickerController
              name="caLicenseExpirationDate"
              control={control}
              label={t("lable.caLicenseExpirationDate")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />

            <InputController
              name="caCarNumber"
              control={control}
              type="text"
              label={t("lable.caCarNumber")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
          </Grid>

          <MDTypography variant="h6" sx={{ margin: "20px 0px 14px 0px" }}>
            {t("title.ca.2")}
          </MDTypography>
          <Grid container columnSpacing={3}>
            <InputController
              name="caVictimDriverName"
              control={control}
              type="text"
              label={t("lable.caVictimDriverName")}
              // textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />

            <InputController
              name="caVictimOtherParty"
              control={control}
              type="text"
              label={t("lable.caVictimOtherParty")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />

            {/* <DatePickerController
              name="caVictimBirthday"
              control={control}
              label={t("lable.caVictimBirthday")}
              maxDate={moment()}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            /> */}
            <InputController
              name="caVictimPhoneNumber"
              control={control}
              type="text"
              label={t("lable.caVictimPhoneNumber")}
              isAutoFormatPhoneNumber
              // textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            {/* <InputController
              name="caVictimAddress"
              control={control}
              type="text"
              label={t("lable.caVictimAddress")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            /> */}
            {/* <InputController
              name="caVictimCarType"
              control={control}
              type="text"
              label={t("lable.caVictimCarType")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            /> */}
            <InputController
              name="caVictimCarNumber"
              control={control}
              type="text"
              label={t("lable.caVictimCarNumber")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <InputController
              name="caVictimNote"
              control={control}
              type="text"
              label={t("lable.caVictimNote")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <InputController
              name="caAccidentSite"
              control={control}
              type="text"
              label={t("lable.caAccidentSite")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <SelectController
              name="caAccidentType"
              control={control}
              label={t("lable.caAccidentType")}
              option={[
                { id: 1, name: t("lable.caAccidentType.1") },
                { id: 2, name: t("lable.caAccidentType.2") },
                { id: 3, name: t("lable.caAccidentType.3") },
                { id: 4, name: t("lable.caAccidentType.4") },
              ]}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />

            <InputController
              name="caAccidentStatus"
              control={control}
              type="text"
              label={t("lable.caAccidentStatus")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />

            <SelectController
              name="caAccidentReportPolice"
              control={control}
              label={t("lable.caAccidentReportPolice")}
              option={[
                { id: 1, name: t("lable.caAccidentReportPolice.1") },
                { id: 0, name: t("lable.caAccidentReportPolice.2") },
              ]}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            {!!watch("caAccidentReportPolice") && (
              <InputController
                name="caAccidentReportPoliceContent"
                control={control}
                type="text"
                label={t("lable.caAccidentReportPoliceContent")}
                disabled={isSubAdmin || disabled}
                grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
              />
            )}
            <InputController
              name="caAccidentDestination"
              control={control}
              type="text"
              label={t("lable.caAccidentDestination")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <InputController
              name="caSpecialNote"
              control={control}
              type="text"
              label={t("text.input.note")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
          </Grid>
          <UploadFileController
            name="caFiles"
            label={t("lable.caFiles")}
            fileList={fileList}
            setFileList={setFileList}
            disabled={isSubAdmin || disabled}
          />
          {renderButtonAction()}
        </MDBox>
      </MDBox>
    </Card>
  );
}
export default FormAccidentCars;
