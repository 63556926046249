import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  TEXT_OCCURRENCE_TYPE,
  TEXT_INSURANCE_TYPE,
  TEXT_BU_ACCIDENT_TYPE,
} from "constants/accident";

function AccidentInformation({ exportPDF, dataAnken, loadingExportPDF }) {
  const { t } = useTranslation();
  return (
    <Card style={{ minHeight: "450px" }}>
      <MDBox
        mx={2}
        mt={-3}
        py={2}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <MDTypography variant="h6" color="white">
          {t("desc.co")}
        </MDTypography>
        <LoadingButton
          onClick={exportPDF}
          endIcon={<FileDownloadIcon />}
          loading={loadingExportPDF}
          disabled={loadingExportPDF}
          loadingPosition="end"
          variant="outlined"
          // color="white"
          style={{ color: "#fff", border: "1px #fff solid", padding: "0px 14px" }}
        >
          {t("detailAnken.btn.export")}
        </LoadingButton>
      </MDBox>
      {/* 11 事故日時 */}
      <MDBox display="flex" py={1} px={3} pt={3}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("listAken.occurrenceTime")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;
            {dataAnken?.data?.occurrenceTime
              ? moment(dataAnken?.data?.occurrenceTime)
                  .utcOffset("+09:00")
                  .format("YYYY/MM/DD HH:mm")
              : ""}
          </MDTypography>
        </MDTypography>
      </MDBox>
      {/* 12 事故種類 */}
      <MDBox display="flex" py={1} px={3} pt={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("lable.occurrenceType")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{TEXT_OCCURRENCE_TYPE[dataAnken?.data?.occurrenceType]}
          </MDTypography>
        </MDTypography>
      </MDBox>
      {/* 13 対象保険 */}
      <MDBox display="flex" py={1} px={3} pt={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("lable.insuranceType")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;
            {dataAnken?.data?.insuranceType
              ? TEXT_INSURANCE_TYPE[dataAnken?.data?.insuranceType]
              : ""}
          </MDTypography>
        </MDTypography>
      </MDBox>
      {/* 14 報告者名 */}
      <MDBox display="flex" py={1} px={3} pt={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("detailUserForAnken.reporterName")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{dataAnken?.data?.reporterName}
          </MDTypography>
        </MDTypography>
      </MDBox>
      {/* 15 発生事故 */}
      <MDBox display="flex" py={1} px={3} pt={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("lable.coAccidentType")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;
            {dataAnken?.data?.dataOccurrenceType?.otAccidentOccurred ||
              TEXT_BU_ACCIDENT_TYPE[dataAnken?.data?.dataOccurrenceType?.buAccidentType]}
          </MDTypography>
        </MDTypography>
      </MDBox>
      {/* 16 事故内容 */}
      <MDBox display="flex" py={1} px={3} pt={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("detailUserForAnken.detailsAccident")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;
            {dataAnken?.data?.dataOccurrenceType?.caAccidentStatus ||
              dataAnken?.data?.dataOccurrenceType?.codamageSituation ||
              dataAnken?.data?.dataOccurrenceType?.inAccidentStatus ||
              dataAnken?.data?.dataOccurrenceType.buAccidentStatus ||
              dataAnken?.data?.dataOccurrenceType?.otAccidentStatus}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default AccidentInformation;
