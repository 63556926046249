import { useNavigate, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { post } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import InputController from "components/withController/InputController";
import {
  checkTopLevelDomailEmail,
  regexNameFullWidth,
  regexPassword,
  regexPostCode,
  regexPhoneNumber,
} from "utils/regex";
import { ORGANIZATION, POST_CODE_LENGTH } from "constants/user";
import InputRadioController from "components/withController/InputRadioController";
import MDTypography from "components/MDTypography";

function CreateUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useLocation();

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [userId, setUserId] = useState(123);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().when("organization", {
      is: (value) => value === ORGANIZATION.INDIVIDUAL,
      then: Yup.string()
        .required(t("validate.E001"))
        .matches(regexNameFullWidth, t("validate.E003"))
        .max(50, t("validate.E003")),
    }),
    companyName: Yup.string().when("organization", {
      is: (value) => value === ORGANIZATION.LEGAL_ENTITY,
      then: Yup.string()
        .required(t("validate.E001"))
        .matches(regexNameFullWidth, t("validate.E003"))
        .max(50, t("validate.E003")),
    }),
    contractName: Yup.string()
      .test("", t("validate.E003"), (value) => {
        if (!value) {
          return true;
        }
        if (!value.trim().length) {
          return false;
        }
        return regexNameFullWidth.test(value);
      })
      .max(50, t("validate.E003")),
    phone: Yup.string().required(t("validate.E001")).matches(regexPhoneNumber, t("validate.E005")),
    email: Yup.string()
      .nullable(t("validate.E001"))
      .required(t("validate.E001"))
      .matches(/^[ -~]+$/, t("validate.E007"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
    password: Yup.string()
      .nullable(t("validate.E001"))
      .required(t("validate.E001"))
      .matches(regexPassword, t("validate.E027")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("validate.E033"))
      .required(t("validate.E001")),
    postCode: Yup.string().test("", t("validate.E009"), (value) => {
      if (!value) {
        return true;
      }
      return regexPostCode.test(value);
    }),
    address: Yup.string().max(100, t("validate.E014")),
    industry: Yup.string().max(255, t("validate.E015")),
    constructionType: Yup.string().max(255, t("validate.E015")),
    salesRepresentative: Yup.string()
      .required(t("validate.E001"))
      .matches(regexNameFullWidth, t("validate.E003"))
      .max(50, t("validate.E003")),
    remarks: Yup.string().max(255, t("validate.E015")),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: null,
      password: null,
      confirmPassword: "",
      fullName: "",
      companyName: "",
      googleId: null,
      twitterId: null,
      phone: "",
      postCode: "",
      address: "",
      industry: "",
      constructionType: "",
      salesRepresentative: "",
      remarks: "",
      organization: ORGANIZATION.LEGAL_ENTITY,
      contractName: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const postRegisterUser = async (data) => post("api/register", data);

  const { mutate: registerUser, isLoading } = useMutation(postRegisterUser, {
    onSuccess: () => {
      openSuccessSB();
      setTimeout(() => {
        navigate("/users");
      }, 1000);
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.createUser");
    return () => {
      document.title = t("title");
    };
  }, []);

  useEffect(async () => {
    try {
      if (watch("postCode")?.length === POST_CODE_LENGTH) {
        const response = await fetch(
          `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${watch("postCode")}`
        );
        const result = await response.json();
        if (result.results === null) {
          setValue("address", "");
          setError("postCode", { type: "custom", message: t("validate.E032") });
        }
        if (result.results !== null) {
          setValue("address", `${result.results[0]?.address1}－${result.results[0]?.address2}`);
        }
      } else {
        setValue("address", "");
      }
    } catch (error) {
      setError("postCode", { type: "custom", message: t("validate.E032") });
    }
  }, [watch("postCode")]);

  const onRegister = (data) => {
    const newData = {
      ...data,
      email: data.email.trim(),
      fullName: data.organization === ORGANIZATION.INDIVIDUAL ? data.fullName.trim() : null,
      companyName: data.organization === ORGANIZATION.LEGAL_ENTITY ? data.companyName.trim() : null,
      contractName: data.contractName?.trim() || null,
      postCode: data.postCode || null,
      address: data.address || null,
      industry: data.industry || null,
      constructionType: data.constructionType || null,
      salesRepresentative: data.salesRepresentative.trim(),
      remarks: data.remarks || null,
    };
    registerUser(newData);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6}>
        <Card component="form">
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {t("title.createUser")}
            </MDTypography>
          </MDBox>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={2} pb={1}>
              <InputRadioController
                control={control}
                name="organization"
                option={[
                  {
                    label: t("detailUserForAnkenCompanyNameText"),
                    value: ORGANIZATION.LEGAL_ENTITY,
                  },
                  { label: t("detailUserForAnkenUserNameText"), value: ORGANIZATION.INDIVIDUAL },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MDBox pt={0} px={3}>
                <MDBox>
                  {watch("organization") === ORGANIZATION.INDIVIDUAL && (
                    <InputController
                      name="fullName"
                      control={control}
                      type="text"
                      label={t("lable.name")}
                      textRequired="*"
                    />
                  )}
                  {watch("organization") === ORGANIZATION.LEGAL_ENTITY && (
                    <InputController
                      name="companyName"
                      control={control}
                      type="text"
                      label={t("lable.companyName")}
                      textRequired="*"
                    />
                  )}
                  <InputController
                    name="contractName"
                    control={control}
                    type="text"
                    label={t("lable.nameOfPersonCharge")}
                  />
                  <InputController
                    name="phone"
                    control={control}
                    type="tel"
                    label={t("lable.phoneNumber")}
                    textRequired="*"
                    isAutoFormatPhoneNumber
                  />
                  <InputController
                    name="email"
                    control={control}
                    type="email"
                    label={t("lable.email")}
                    textRequired="*"
                  />
                  <InputController
                    name="password"
                    control={control}
                    type="password"
                    label={t("lable.password")}
                    textRequired="*"
                  />
                  <InputController
                    name="confirmPassword"
                    control={control}
                    type="password"
                    label={t("lable.password.confirm")}
                    textRequired="*"
                  />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MDBox pt={0} px={3}>
                <MDBox>
                  <InputController
                    name="postCode"
                    control={control}
                    type="text"
                    label={t("lable.postCode")}
                  />
                  <InputController
                    name="address"
                    control={control}
                    type="text"
                    label={t("lable.userAddress")}
                  />
                  <InputController
                    name="industry"
                    control={control}
                    type="text"
                    label={t("lable.industry")}
                  />
                  {/* <InputController
                    name="constructionType"
                    control={control}
                    type="text"
                    label={t("lable.constructionType")}
                  /> */}
                  <InputController
                    name="salesRepresentative"
                    control={control}
                    type="text"
                    label={t("lable.salesRepresentative")}
                    textRequired="*"
                  />
                  <InputController
                    name="remarks"
                    control={control}
                    type="text"
                    label={t("lable.note")}
                    multiline
                    rows={4}
                    maxRows={4}
                  />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={0} mt={1} pb={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MDBox mt={2} mb={1} px={3}>
                <MDButton
                  variant="outlined"
                  color="info"
                  fullWidth
                  onClick={() => navigate(-1)}
                  disabled={isLoading}
                >
                  {t("createUser.btn.back")}
                </MDButton>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MDBox mt={2} mb={1} px={3}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  onClick={handleSubmit(onRegister)}
                  disabled={isLoading}
                  fullWidth
                >
                  {t("createUser.btn.register")}
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Footer />
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("createUser.register.success")}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("createUser.register.failed")}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </DashboardLayout>
  );
}

export default CreateUser;
