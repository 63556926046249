import { Link, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import masterCardLogo from "assets/images/logos/logo.jpg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { post } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";

function SendEmail() {
  const { t } = useTranslation();
  const params = useLocation();
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [second, setSecond] = useState(60);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const postSendEmail = async (data) => post("api/send-email", data);

  const { mutate: sendEmail, isLoading } = useMutation(postSendEmail, {
    onSuccess: (data) => {
      openSuccessSB();
      localStorage.setItem("timeSendMail", moment());
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    const timeOld = localStorage.getItem("timeSendMail");
    if (!timeOld) {
      localStorage.setItem("timeSendMail", moment());
    }
  }, []);

  useEffect(() => {
    document.title = t("title.sendEmail");
    return () => {
      document.title = t("title");
    };
  }, []);

  const reSendMail = () => {
    const timeNow = moment();
    const timeOld = localStorage.getItem("timeSendMail");
    const diffInSeconds = timeNow.diff(timeOld, "seconds");
    if (diffInSeconds >= 60) {
      sendEmail({ email: params.state?.email });
    } else {
      setSecond(60 - diffInSeconds);
      openErrorSB();
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          // bgColor="info"
          borderRadius="lg"
          // coloredShadow="info"
          mx={2}
          mt={-4}
          // p={2}
          // mb={1}
          textAlign="center"
        >
          <MDBox
            component="img"
            src={masterCardLogo}
            alt="master card"
            width="100%"
            borderRadius="lg"
            coloredShadow="info"
          />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography variant="body2" color="text">
            {params.state?.email} {t("sendEmail.desc")}
          </MDTypography>
          <MDBox component="form" role="form">
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  onClick={reSendMail}
                  sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}
                >
                  {t("sendEmail.reSend")}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("sendEmail.success")}
        // dateTime="11 mins ago"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("sendEmail.failed", { second })}
        // dateTime="11 mins ago"
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </BasicLayout>
  );
}

export default SendEmail;
