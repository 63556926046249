import { OCCURRENCE_TYPE } from "constants/accident";
import { useTranslation } from "react-i18next";
import {
  regexAddress,
  regexNameFullWidth,
  regexPhoneNumber,
  checkTopLevelDomailEmail,
} from "utils/regex";
import * as Yup from "yup";

const useValidateFormAccident = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    reporterName: Yup.string()
      .required(t("validate.E001"))
      .trim(t("validate.E001"))
      .matches(regexNameFullWidth, t("validate.E003"))
      .max(50, t("validate.E003")),
    reporterPhoneNumber: Yup.string()
      .required(t("validate.E001"))
      .matches(regexPhoneNumber, t("validate.E005")),
    reporterEmail: Yup.string()
      .required(t("validate.E001"))
      .matches(/^[ -~]+$/, t("validate.E007"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
    occurrenceType: Yup.string().required(t("validate.E019")),
    // nameSigning: Yup.string()
    //   .required(t("validate.E001"))
    //   .trim(t("validate.E001"))
    //   .matches(regexNameFullWidth, t("validate.E003"))
    //   .max(50, t("validate.E003")),
    constractPhoneNumber: Yup.string().test("", t("validate.E005"), (value) => {
      if (!value) {
        return true;
      }
      return regexPhoneNumber.test(value);
    }),
    address: Yup.string()
      // .test("", t("validate.E004"), (value) => {
      //   if (!value) {
      //     return true;
      //   }
      //   return regexAddress.test(value);
      // })
      .max(100, t("validate.E014")),

    // t_accident_cars
    caDriverName: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        .matches(regexNameFullWidth, t("validate.E003"))
        .max(50, t("validate.E003")),
    }),
    // caDriverType: Yup.string().when("occurrenceType", {
    //   is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
    //   then: Yup.string().required(t("validate.E019")),
    // }),
    caDriverContract: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string().required(t("validate.E001")).matches(regexPhoneNumber, t("validate.E005")),
    }),
    caCarNumber: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        .max(255, t("validate.E015")),
    }),
    caVictimDriverName: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string()
        // .required(t("validate.E001"))
        // .trim(t("validate.E001"))
        // .matches(regexNameFullWidth, t("validate.E003"))
        // .max(50, t("validate.E003")),
        .test("", t("validate.E003"), (value) => {
          if (!value) {
            return true;
          }
          if (!value.trim().length) {
            return false;
          }
          return regexNameFullWidth.test(value);
        })
        .max(50, t("validate.E003")),
    }),
    caVictimOtherParty: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string()
        .test("", t("validate.E003"), (value) => {
          if (!value) {
            return true;
          }
          if (!value.trim().length) {
            return false;
          }
          return regexNameFullWidth.test(value);
        })
        .max(50, t("validate.E003")),
    }),
    caVictimPhoneNumber: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string()
        // .required(t("validate.E001"))
        // .matches(regexPhoneNumber, t("validate.E005"))
        .test("", t("validate.E005"), (value) => {
          if (!value) {
            return true;
          }
          return regexPhoneNumber.test(value);
        }),
    }),
    caVictimAddress: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string()
        // .test("", t("validate.E004"), (value) => {
        //   if (!value) {
        //     return true;
        //   }
        //   return regexAddress.test(value);
        // })
        .max(100, t("validate.E014")),
    }),
    caVictimCarType: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string().max(255, t("validate.E015")),
    }),
    caVictimCarNumber: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string().max(255, t("validate.E015")),
    }),
    caVictimNote: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string().max(255, t("validate.E015")),
    }),
    caAccidentSite: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string()
        .required(t("validate.E001"))
        // .trim(t("validate.E001"))
        // .matches(regexAddress, t("validate.E004"))
        .max(100, t("validate.E014")),
    }),
    caAccidentType: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string().required(t("validate.E019")),
    }),
    caAccidentStatus: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        .max(255, t("validate.E015")),
    }),
    caAccidentReportPolice: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string().required(t("validate.E019")),
    }),
    caAccidentReportPoliceContent: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string().max(255, t("validate.E015")),
    }),
    caAccidentDestination: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string()
        // .test("", t("validate.E004"), (value) => {
        //   if (!value) {
        //     return true;
        //   }
        //   return regexAddress.test(value);
        // })
        .max(100, t("validate.E014")),
    }),
    caSpecialNote: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.CARS,
      then: Yup.string().max(500, t("validate.number.max500")),
    }),

    // t_accident_compensations
    coAccidentSite: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.COMPENSATIONS,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        // .matches(regexAddress, t("validate.E004"))
        .max(100, t("validate.E014")),
    }),
    coVictimName: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.COMPENSATIONS,
      then: Yup.string()
        // .required(t("validate.E001"))
        // .trim(t("validate.E001"))
        // .matches(regexNameFullWidth, t("validate.E003"))
        // .max(50, t("validate.E003")),
        .test("", t("validate.E003"), (value) => {
          if (!value) {
            return true;
          }
          if (!value.trim().length) {
            return false;
          }
          return regexNameFullWidth.test(value);
        })
        .max(50, t("validate.E003")),
    }),
    coVictimContract: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.COMPENSATIONS,
      then: Yup.string()
        // .test("", t("validate.E004"), (value) => {
        //   if (!value) {
        //     return true;
        //   }
        //   return regexAddress.test(value);
        // })
        .max(100, t("validate.E014")),
    }),
    coAccidentType: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.COMPENSATIONS,
      then: Yup.string().required(t("validate.E019")),
    }),
    coVictimJob: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.COMPENSATIONS,
      // then: Yup.string()
      //   .required(t("validate.E001"))
      //   .trim(t("validate.E001"))
      //   .max(255, t("validate.E015")),
      then: Yup.string().max(255, t("validate.E015")),
    }),
    codamageSituation: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.COMPENSATIONS,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        .max(255, t("validate.E015")),
    }),
    coMeasures: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.COMPENSATIONS,
      then: Yup.string().max(255, t("validate.E015")),
    }),
    coNote: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.COMPENSATIONS,
      then: Yup.string().max(500, t("validate.number.max500")),
    }),
    coSpecialNote: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.COMPENSATIONS,
      then: Yup.string().max(500, t("validate.number.max500")),
    }),

    // t_accident_injuries
    inVictimName: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.INJURIES,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        .matches(regexNameFullWidth, t("validate.E003"))
        .max(50, t("validate.E003")),
    }),
    // inVictimPosition: Yup.string().when("occurrenceType", {
    //   is: (value) => Number(value) === OCCURRENCE_TYPE.INJURIES,
    //   then: Yup.string().required(t("validate.E019")),
    // }),
    inInjuredLocation: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.INJURIES,
      // then: Yup.string()
      //   .required(t("validate.E001"))
      //   .trim(t("validate.E001"))
      //   .max(255, t("validate.E015")),
      then: Yup.string().max(255, t("validate.E015")),
    }),
    inAccidentSite: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.INJURIES,
      then: Yup.string()
        .required(t("validate.E001"))
        // .matches(regexAddress, t("validate.E004"))
        .max(100, t("validate.E014")),
    }),
    inAccidentStatus: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.INJURIES,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        .max(255, t("validate.E015")),
    }),
    // inCompensationUsage: Yup.string().when("occurrenceType", {
    //   is: (value) => Number(value) === OCCURRENCE_TYPE.INJURIES,
    //   then: Yup.string().required(t("validate.E019")),
    // }),
    inSpecialNote: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.INJURIES,
      then: Yup.string().max(500, t("validate.number.max500")),
    }),

    // t_accident_buildings
    buAccidentType: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.BUILDINGS,
      then: Yup.string().required(t("validate.E019")),
    }),
    buAccidentSite: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.BUILDINGS,
      then: Yup.string()
        .required(t("validate.E001"))
        // .trim(t("validate.E001"))
        // .matches(regexAddress, t("validate.E004"))
        .max(100, t("validate.E014")),
    }),
    buAccidentStatus: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.BUILDINGS,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        .max(255, t("validate.E015")),
    }),
    buBuildingType: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.BUILDINGS,
      // then: Yup.string()
      //   .required(t("validate.E001"))
      //   .trim(t("validate.E001"))
      //   .max(255, t("validate.E015")),
      then: Yup.string().max(255, t("validate.E015")),
    }),
    buMeasures: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.BUILDINGS,
      // then: Yup.string()
      //   .required(t("validate.E001"))
      //   .trim(t("validate.E001"))
      //   .max(255, t("validate.E015")),
      then: Yup.string().max(255, t("validate.E015")),
    }),
    buNote: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.BUILDINGS,
      then: Yup.string().max(500, t("validate.number.max500")),
    }),
    buSpecialNote: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.BUILDINGS,
      then: Yup.string().max(500, t("validate.number.max500")),
    }),

    // t_accident_others
    otAccidentSite: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.OTHERS,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        // .matches(regexAddress, t("validate.E004"))
        .max(100, t("validate.E014")),
    }),
    otAccidentOccurred: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.OTHERS,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        .max(255, t("validate.E015")),
    }),
    otAccidentStatus: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.OTHERS,
      then: Yup.string()
        .required(t("validate.E001"))
        .trim(t("validate.E001"))
        .max(255, t("validate.E015")),
    }),
    otNote: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.OTHERS,
      then: Yup.string().max(500, t("validate.number.max500")),
    }),
    otSpecialNote: Yup.string().when("occurrenceType", {
      is: (value) => Number(value) === OCCURRENCE_TYPE.OTHERS,
      then: Yup.string().max(500, t("validate.number.max500")),
    }),
  });

  const defaultValues = {
    reporterName: "",
    reporterPhoneNumber: "",
    reporterEmail: "",
    occurrenceTime: null,
    occurrenceTimeWithDate: null,
    occurrenceTimeWithTime: null,
    occurrenceType: 1,
    reciprocalStaff: "",
    nameSigning: "",
    nameContractSigning: "",
    constractPhoneNumber: "",
    constractEmail: "",
    address: "",
    constractUserId: "",

    // t_accident_cars
    caDriverName: "",
    caDriverType: "",
    caDriverContract: "",
    caDriverBirthday: null,
    caLicenseExpirationDate: null,
    caCarNumber: "",
    caVictimDriverName: "",
    caVictimOtherParty: "",
    caVictimBirthday: null,
    caVictimPhoneNumber: "",
    caVictimAddress: "",
    caVictimCarType: "",
    caVictimCarNumber: "",
    caVictimNote: "",
    caAccidentSite: "",
    caAccidentType: "",
    caAccidentStatus: "",
    caAccidentReportPolice: "",
    caAccidentReportPoliceContent: "",
    caAccidentDestination: "",
    caNote: "",
    caSpecialNote: "",
    ca_files: "",

    // t_accident_compensations
    coAccidentSite: "",
    coVictimName: "",
    coVictimContract: "",
    coAccidentType: "",
    coVictimJob: "",
    codamageSituation: "",
    coMeasures: "",
    coNote: "",
    co_files: "",

    // t_accident_injuries
    inVictimName: "",
    inVictimPosition: "",
    inInjuredLocation: "",
    inAccidentSite: "",
    inAccidentStatus: "",
    inCompensationUsage: "",
    inSpecialNote: "",
    in_files: "",

    // t_accident_buildings
    buAccidentType: "",
    buAccidentSite: "",
    buAccidentStatus: "",
    buBuildingType: "",
    buMeasures: "",
    buNote: "",
    buFiles: "",
    buSpecialNote: "",

    // t_accident_others
    otAccidentSite: "",
    otAccidentOccurred: "",
    otAccidentStatus: "",
    otNote: "",
    otFiles: "",
    otSpecialNote: "",
  };
  return { validationSchema, defaultValues };
};

export default useValidateFormAccident;
