/**
Comment
*/

function configs(labels, datasets) {
  let data;
  if (Array.isArray(datasets)) {
    data = datasets.map((item) => ({
      label: item.label,
      tension: 0,
      pointRadius: 5,
      pointBorderColor: "transparent",
      pointBackgroundColor: item.color || "rgba(255, 255, 255, .8)",
      borderColor: item.color || "rgba(255, 255, 255, .8)",
      borderWidth: 4,
      backgroundColor: "transparent",
      fill: true,
      data: item.data,
      maxBarThickness: 6,
      hidden: !item.data.filter((item) => item !== 0).length,
      title: item.title,
    }));
  } else {
    data = [
      {
        label: datasets.label,
        tension: 0,
        pointRadius: 5,
        pointBorderColor: "transparent",
        pointBackgroundColor: "rgba(255, 255, 255, .8)",
        borderColor: "rgba(255, 255, 255, .8)",
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        data: datasets.data,
        maxBarThickness: 6,
      },
    ];
  }

  return {
    data: {
      labels,
      datasets: data,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            title: (ctx) =>
              ctx[0]?.dataset?.title ? `${ctx[0]?.dataset?.title}/${ctx[0]?.label}` : ctx[0]?.label,
          },
        },
        legend: {
          fullSize: true,
          display: false,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            pinch: {
              enabled: true,
            },
            wheel: {
              enabled: true,
            },
            drag: {
              enabled: true,
            },
            mode: "x",
          },
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "rgba(255, 255, 255, .2)",
          },
          ticks: {
            precision: 0,
            display: true,
            color: "#f8f9fa",
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            color: "#f8f9fa",
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}

export default configs;
