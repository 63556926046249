// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useTranslation } from "react-i18next";

function Expired() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("title.resetPassword");
    return () => {
      document.title = t("title");
    };
  }, []);
  return (
    <BasicLayout>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography variant="h4" color="text" align="center" sx={{ paddingBottom: "20px" }}>
            新しいパスワードを作成
          </MDTypography>
          <MDTypography variant="body2" color="text">
            パスワードリセットのリンクが無効/期限切れになっています。{" "}
            <MDTypography
              // component={Link}
              // to="/forgot-password"
              variant="span"
              color="info"
              fontWeight="medium"
              // textGradient
              onClick={() => navigate("/forgot-password")}
              sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}
            >
              新しいパスワードリセット
            </MDTypography>{" "}
            をリクエストしてください。
          </MDTypography>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Expired;
