/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { post, get, put } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useValidateFormAccident from "hooks/useValidateFormAccident";
import FormAccident from "components/formAccident/FormAccident";
import FormAccidentCars from "components/formAccident/FormAccidentCars";
import FormAccidentCompensations from "components/formAccident/FormAccidentCompensations";
import FormAccidentInjuries from "components/formAccident/FormAccidentInjuries";
import FormAccidentBuildings from "components/formAccident/FormAccidentBuildings";
import FormAccidentOthers from "components/formAccident/FormAccidentOthers";
import { OCCURRENCE_TYPE } from "constants/accident";
import moment from "moment";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { regexNameFullWidth, checkTopLevelDomailEmail } from "utils/regex";

const CreateAnken = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const { validationSchema, defaultValues } = useValidateFormAccident();
  const validationMoreAnken = Yup.object().shape({
    nameContractSigning: Yup.string()
      .required(t("validate.E001"))
      .trim(t("validate.E001"))
      .matches(regexNameFullWidth, t("validate.E003"))
      .max(50, t("validate.E003")),
    constractEmail: Yup.string()
      .required(t("validate.E001"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
    insuranceType: Yup.string().required(t("validate.E019")),
    status: Yup.string().required(t("validate.E019")),
    constractUserId: Yup.string().required(t("validate.E001")),
    nameSigning: Yup.string()
      .required(t("validate.E001"))
      .trim(t("validate.E001"))
      .matches(regexNameFullWidth, t("validate.E003"))
      .max(50, t("validate.E003")),
  });
  const defaultValuesMoreAnken = {
    insuranceType: "",
    status: "",
  };
  const resultValidate = validationSchema.concat(validationMoreAnken);
  const resultDefaultValues = { ...defaultValues, ...defaultValuesMoreAnken };

  const [fileList, setFileList] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const { control, handleSubmit, watch, setValue, reset, clearErrors } = useForm({
    defaultValues: resultDefaultValues,
    mode: "onChange",
    resolver: yupResolver(resultValidate),
  });

  const postAnkenAPI = async (data) => post("api/anken", data);

  const { mutate: createAnken, isLoading: isLoadingCreate } = useMutation(postAnkenAPI, {
    onSuccess: (data) => {
      openSuccessSB();
      setTimeout(() => {
        navigate("/anken");
      }, 1000);
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.createAnken");
    return () => {
      document.title = t("title");
    };
  }, []);

  const onSubmit = (params) => {
    const { data } = params;
    const formData = new FormData();
    formData.append("reporterName", data.reporterName.trim());
    formData.append("reporterPhoneNumber", data.reporterPhoneNumber);
    formData.append("reporterEmail", data.reporterEmail.trim());
    data.occurrenceTime &&
      formData.append("occurrenceTime", moment(data.occurrenceTime).format("YYYY-MM-DD HH:mm"));
    formData.append("occurrenceType", data.occurrenceType);
    formData.append("nameSigning", data.nameSigning.trim());
    formData.append("nameContractSigning", data.nameContractSigning.trim());
    data.constractPhoneNumber && formData.append("constractPhoneNumber", data.constractPhoneNumber);
    formData.append("constractEmail", data.constractEmail);
    data.address && formData.append("address", data.address);
    formData.append("insuranceType", data.insuranceType);
    formData.append("status", data.status);
    data.reciprocalStaff && formData.append("reciprocalStaff", data.reciprocalStaff);

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.CARS) {
      formData.append("caDriverName", data.caDriverName);
      formData.append("caDriverType", data.caDriverType);
      formData.append("caDriverContract", data.caDriverContract);
      data.caDriverBirthday &&
        formData.append("caDriverBirthday", moment(data.caDriverBirthday).format("YYYY-MM-DD"));
      data.caLicenseExpirationDate &&
        formData.append(
          "caLicenseExpirationDate",
          moment(data.caLicenseExpirationDate).format("YYYY-MM-DD")
        );
      formData.append("caCarNumber", data.caCarNumber);
      data.caVictimDriverName && formData.append("caVictimDriverName", data.caVictimDriverName);
      data.caVictimOtherParty &&
        formData.append("caVictimOtherParty", data.caVictimOtherParty.trim());
      data.caVictimBirthday &&
        formData.append("caVictimBirthday", moment(data.caVictimBirthday).format("YYYY-MM-DD"));
      data.caVictimPhoneNumber && formData.append("caVictimPhoneNumber", data.caVictimPhoneNumber);
      data.caVictimAddress && formData.append("caVictimAddress", data.caVictimAddress);
      data.caVictimCarType && formData.append("caVictimCarType", data.caVictimCarType);
      data.caVictimCarNumber && formData.append("caVictimCarNumber", data.caVictimCarNumber);
      data.caVictimNote && formData.append("caVictimNote", data.caVictimNote);
      formData.append("caAccidentSite", data.caAccidentSite);
      formData.append("caAccidentType", data.caAccidentType);
      formData.append("caAccidentStatus", data.caAccidentStatus);
      formData.append("caAccidentReportPolice", data.caAccidentReportPolice);
      data.caAccidentReportPoliceContent &&
        formData.append("caAccidentReportPoliceContent", data.caAccidentReportPoliceContent);
      data.caAccidentDestination &&
        formData.append("caAccidentDestination", data.caAccidentDestination);
      data.caSpecialNote && formData.append("caSpecialNote", data.caSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.COMPENSATIONS) {
      formData.append("coAccidentSite", data.coAccidentSite);
      data.coVictimName && formData.append("coVictimName", data.coVictimName);
      data.coVictimContract && formData.append("coVictimContract", data.coVictimContract);
      formData.append("coAccidentType", data.coAccidentType);
      data.coVictimJob && formData.append("coVictimJob", data.coVictimJob);
      formData.append("codamageSituation", data.codamageSituation);
      formData.append("coMeasures", data.coMeasures);
      data.coNote && formData.append("coNote", data.coNote);
      data.coSpecialNote && formData.append("coSpecialNote", data.coSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.INJURIES) {
      formData.append("inVictimName", data.inVictimName.trim());
      data.inVictimPosition && formData.append("inVictimPosition", data.inVictimPosition);
      data.inInjuredLocation && formData.append("inInjuredLocation", data.inInjuredLocation);
      formData.append("inAccidentSite", data.inAccidentSite);
      formData.append("inAccidentStatus", data.inAccidentStatus);
      data.inCompensationUsage && formData.append("inCompensationUsage", data.inCompensationUsage);
      data.inSpecialNote && formData.append("inSpecialNote", data.inSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.BUILDINGS) {
      formData.append("buAccidentType", data.buAccidentType);
      formData.append("buAccidentSite", data.buAccidentSite);
      formData.append("buAccidentStatus", data.buAccidentStatus);
      data.buBuildingType && formData.append("buBuildingType", data.buBuildingType);
      data.buMeasures && formData.append("buMeasures", data.buMeasures);
      data.buNote && formData.append("buNote", data.buNote);
      data.buSpecialNote && formData.append("buSpecialNote", data.buSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.OTHERS) {
      formData.append("otAccidentSite", data.otAccidentSite);
      formData.append("otAccidentOccurred", data.otAccidentOccurred);
      formData.append("otAccidentStatus", data.otAccidentStatus);
      data.otNote && formData.append("otNote", data.otNote);
      data.otSpecialNote && formData.append("otSpecialNote", data.otSpecialNote);
    }
    fileList.forEach((file) => {
      formData.append("files", file.originFileObj, file.name);
    });
    createAnken(formData);
  };
  const renderButtonAction = () => (
    <Grid container columnSpacing={3} rowSpacing={2} mt={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <MDBox>
          <MDButton
            variant="outlined"
            color="info"
            fullWidth
            onClick={() => navigate(-1)}
            disabled={isLoadingCreate}
          >
            {t("createAnken.btn.back")}
          </MDButton>
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <MDBox>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handleSubmit((data) => onSubmit({ data }))}
            disabled={isLoadingCreate}
          >
            {t("createAnken.btn.register")}
          </MDButton>
        </MDBox>
      </Grid>
    </Grid>
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormAccident
              control={control}
              setValue={setValue}
              clearErrors={clearErrors}
              title={t("createAnken.title")}
              isCreateAnken
              disabled={isLoadingCreate}
            />
            {watch("occurrenceType") === OCCURRENCE_TYPE.CARS && (
              <FormAccidentCars
                control={control}
                watch={watch}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                disabled={isLoadingCreate}
              />
            )}
            {watch("occurrenceType") === OCCURRENCE_TYPE.COMPENSATIONS && (
              <FormAccidentCompensations
                control={control}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                disabled={isLoadingCreate}
              />
            )}
            {watch("occurrenceType") === OCCURRENCE_TYPE.INJURIES && (
              <FormAccidentInjuries
                control={control}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                disabled={isLoadingCreate}
              />
            )}
            {watch("occurrenceType") === OCCURRENCE_TYPE.BUILDINGS && (
              <FormAccidentBuildings
                control={control}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                disabled={isLoadingCreate}
              />
            )}
            {watch("occurrenceType") === OCCURRENCE_TYPE.OTHERS && (
              <FormAccidentOthers
                control={control}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                disabled={isLoadingCreate}
              />
            )}
            <MDSnackbar
              color="success"
              icon="check"
              title={t("success")}
              content={t("createAnken.register.success")}
              open={successSB}
              onClose={closeSuccessSB}
              close={closeSuccessSB}
              bgWhite
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title={t("failed")}
              content={t("createAnken.register.failed")}
              open={errorSB}
              onClose={closeErrorSB}
              close={closeErrorSB}
              bgWhite
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CreateAnken;
