/**
Comment
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsLineChart configurations
import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";
import SelectController from "components/withController/SelectController";
import { Chart } from "chart.js";

Chart.register(zoomPlugin);

function ReportsLineChart({
  color,
  title,
  chart,
  description,
  date,
  control,
  selectName,
  hiddenSelect = false,
  option = [],
  selectLabel,
  onChange,
  additionalSelect = false,
  additionalSelectName,
  additionalSelectOption,
  additionalSelectLabel,
  additionalSelectOnChange,
}) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox p={{ sm: "1rem", xs: 0 }}>
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
            >
              <Line data={data} options={options} />
            </MDBox>
          ),
          [chart, color]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography
            variant="h6"
            textTransform="capitalize"
            justifyContent="center"
            style={{ alignItem: "center" }}
            display="flex"
          >
            <MDBox mt={1} mr={2}>
              {title}
            </MDBox>
            {!hiddenSelect && (
              <MDBox minWidth="100px" maxWidth="200px" display="flex" gap="10px">
                {additionalSelect && (
                  <MDBox width="100px">
                    <SelectController
                      control={control}
                      option={additionalSelectOption}
                      name={additionalSelectName}
                      label={additionalSelectLabel}
                      onChange={additionalSelectOnChange}
                    />
                  </MDBox>
                )}
                <MDBox width="100px">
                  <SelectController
                    control={control}
                    option={option}
                    name={selectName}
                    label={selectLabel}
                    onChange={onChange}
                  />
                </MDBox>
              </MDBox>
            )}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsLineChart;
