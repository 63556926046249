import React, { useEffect } from "react";
import { t } from "i18next";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import StatisticTable from "./components/StatisticTable";
import StatisticChartLine from "./components/StatisticChartLine";

function Statistic() {
  useEffect(() => {
    document.title = t("statisticPage.titlePage");
    return () => {
      document.title = t("statisticPage.titlePage");
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <StatisticChartLine />
      </MDBox>
      <MDBox>
        <StatisticTable />
      </MDBox>
    </DashboardLayout>
  );
}

export default Statistic;
