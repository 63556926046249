export const ROLE_USER = {
  ADMIN: 1,
  SUB_ADMIN: 2,
  USER: 3,
};

export const STATUS_USER = {
  INACTIVE: 0,
  ACTIVE: 1,
};

export const ORGANIZATION = {
  INDIVIDUAL: "1",
  LEGAL_ENTITY: "2",
};

export const POST_CODE_LENGTH = 7;
