import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

function ContactInformation({ exportPDF, dataAnken }) {
  const { t } = useTranslation();
  return (
    <Card style={{ marginTop: "70px" }}>
      <MDBox
        mx={2}
        mt={-3}
        py={2}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ minHeight: "70px" }}
      >
        <MDTypography variant="h6" color="white">
          {t("detailUserForAnken.title.2")}
        </MDTypography>
        {/* <LoadingButton
          onClick={exportPDF}
          endIcon={<FileDownloadIcon />}
          loading={loadingExportPDF}
          disabled={loadingExportPDF}
          loadingPosition="end"
          variant="outlined"
          // color="white"
          style={{ color: "#fff", border: "1px #fff solid", padding: "0px 14px" }}
        >
          {t("detailAnken.btn.export")}
        </LoadingButton> */}
      </MDBox>
      {/* name 氏名 */}
      <MDBox display="flex" py={1} px={3} pt={3}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("listUser.fullName")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{dataAnken?.data?.nameContractSigning}
          </MDTypography>
        </MDTypography>
      </MDBox>
      {/* phone 電話番号 */}
      <MDBox display="flex" py={1} px={3} pt={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("lable.reporterPhoneNumber")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{dataAnken?.data?.constractPhoneNumber}
          </MDTypography>
        </MDTypography>
      </MDBox>
      {/* email メールアドレス */}
      <MDBox
        display="flex"
        py={1}
        pb={3}
        px={3}
        style={{ display: "inline-block", maxWidth: "100%" }}
      >
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("lable.reporterEmail")}:{" "}
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            style={{ wordWrap: "break-word", display: "inline-block", maxWidth: "100%" }}
          >
            {dataAnken?.data?.constractEmail}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default ContactInformation;
