import { Route, Navigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { ROLE_USER } from "constants/user";

import Register from "layouts/authentication/register";
import LoginUser from "layouts/authentication/login-user";
import LoginAdmin from "layouts/authentication/login-admin";
import ForgotPassword from "layouts/authentication/forgot-password";
import SendEmail from "layouts/authentication/send-email";
import ResetPassword from "layouts/authentication/reset-password";
import AuthGoogle from "layouts/authentication/auth-sns/AuthGoogle";
import AuthTwitter from "layouts/authentication/auth-sns/AuthTwitter";
import AccidentInfomation from "layouts/authentication/accident-infomation";
import Expired from "layouts/authentication/expired";
import HandleRedirectGmail from "layouts/authentication/handle-redirect-gmail";

import ListUser from "layouts/users/list-user";
import CreateUser from "layouts/users/create-user";
import DetailUser from "layouts/users/detail-user";

import ListAccident from "layouts/accident/list-accident";
import DetailAccident from "layouts/accident/detail-accident";

import ListAken from "layouts/anken/list-anken";
import CreateAnken from "layouts/anken/create-anken";
import EditAnken from "layouts/anken/edit-anken";
// import DetailUserForAnken from "layouts/anken/detail-user";
import DetailAnken from "layouts/anken/detail-anken";

import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Statistic from "layouts/statistic";

const routes = [
  {
    name: "ホーム",
    path: "/dashboard",
    component: <Dashboard />,
    icon: <Icon fontSize="small">dashboard</Icon>,
    breadCrumb: [{ label: "ホーム", link: null }],
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN, ROLE_USER.USER],
      isPrivate: true,
      type: "collapse",
      key: "dashboard",
      isViewSideBar: true,
      child: false,
    },
  },
  {
    name: "顧客管理​",
    path: null,
    component: null,
    icon: null,
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN],
      isPrivate: true,
      type: "title",
      key: "users-title",
      isViewSideBar: true,
      child: false,
    },
  },
  {
    name: "顧客一覧",
    path: "/users",
    component: <ListUser />,
    icon: <Icon fontSize="small">people_alt_icon</Icon>,
    breadCrumb: [{ label: "顧客一覧", link: null }],
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN],
      isPrivate: true,
      type: "collapse",
      key: "users",
      isViewSideBar: true,
      child: false,
    },
  },
  {
    name: "Create User",
    path: "/users/create",
    component: <CreateUser />,
    icon: <Icon fontSize="small">person_add_icon</Icon>,
    breadCrumb: [
      { label: "顧客一覧", link: "/users" },
      { label: "新規登録", link: null },
    ],
    meta: {
      role: [ROLE_USER.ADMIN],
      isPrivate: true,
      type: "collapse",
      key: "users/create",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "Update User",
    path: "/users/detail/:id",
    component: <DetailUser />,
    icon: <Icon fontSize="small">person_add_icon</Icon>,
    breadCrumb: [
      { label: "顧客一覧", link: "/users" },
      { label: "観客詳細", link: null },
    ],
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN],
      isPrivate: true,
      type: "collapse",
      key: "users/detail/:id",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "事故 初回連絡管理​",
    path: null,
    component: null,
    icon: null,
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN],
      isPrivate: true,
      type: "title",
      key: "accident-title",
      isViewSideBar: true,
      child: false,
    },
  },
  {
    name: "事故 初回連絡一覧",
    path: "/accident",
    component: <ListAccident />,
    icon: <Icon fontSize="small">table_view</Icon>,
    breadCrumb: [{ label: "事故 初回連絡一覧", link: null }],
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN],
      isPrivate: true,
      type: "collapse",
      key: "accident",
      isViewSideBar: true,
      child: false,
    },
  },
  {
    name: "事故 初回連絡詳細",
    path: "/accident/detail/:id",
    component: <DetailAccident />,
    icon: <Icon fontSize="small">table_view</Icon>,
    breadCrumb: [
      { label: "事故 初回連絡一覧", link: "/accident" },
      { label: "事故 初回連絡詳細​", link: null },
    ],
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN],
      isPrivate: true,
      type: "collapse",
      key: "accident/detail/:id",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "案件管理​ ",
    path: null,
    component: null,
    icon: null,
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN, ROLE_USER.USER],
      isPrivate: true,
      type: "title",
      key: "anken-title",
      isViewSideBar: true,
      child: false,
    },
  },
  {
    name: "案件一覧 ",
    path: "/anken",
    component: <ListAken />,
    icon: <Icon fontSize="small">ballot_icon</Icon>,
    breadCrumb: [{ label: "案件一覧", link: null }],
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN, ROLE_USER.USER],
      isPrivate: true,
      type: "collapse",
      key: "anken",
      isViewSideBar: true,
      child: false,
    },
  },
  {
    name: "新規登録 ",
    path: "/anken/create",
    component: <CreateAnken />,
    icon: <Icon fontSize="small">ballot_icon</Icon>,
    breadCrumb: [
      { label: "案件一覧", link: "/anken" },
      { label: "新規登録​", link: null },
    ],
    meta: {
      role: [ROLE_USER.ADMIN],
      isPrivate: true,
      type: "collapse",
      key: "anken/create",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "案件詳細",
    path: "/anken/edit/:id",
    component: <EditAnken />,
    icon: <Icon fontSize="small">table_view</Icon>,
    breadCrumb: [
      { label: "案件一覧", link: "/anken" },
      { label: "案件詳細", link: null },
    ],
    meta: {
      role: [ROLE_USER.ADMIN],
      isPrivate: true,
      type: "collapse",
      key: "accident/detail/:id",
      isViewSideBar: false,
      child: false,
    },
  },
  // {
  //   name: "案件詳細",
  //   path: "/anken/user/:id",
  //   component: <DetailUserForAnken />,
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   breadCrumb: [
  //     { label: "案件一覧", link: "/anken" },
  //     { label: "顧客情報", link: null },
  //   ],
  //   meta: {
  //     role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN],
  //     isPrivate: true,
  //     type: "collapse",
  //     key: "accident/detail/:id",
  //     isViewSideBar: false,
  //     child: false,
  //   },
  // },
  {
    name: "案件詳細",
    path: "/anken/detail/:id",
    component: <DetailAnken />,
    icon: <Icon fontSize="small">table_view</Icon>,
    breadCrumb: [
      { label: "案件一覧", link: "/anken" },
      { label: "案件詳細", link: null },
    ],
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN, ROLE_USER.USER],
      isPrivate: true,
      type: "collapse",
      key: "accident/detail/:id",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "Tables",
    path: "/tables",
    component: <Tables />,
    icon: <Icon fontSize="small">table_view</Icon>,
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN, ROLE_USER.USER],
      isPrivate: true,
      type: "collapse",
      key: "tables",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "Billing",
    path: "/billing",
    component: <Billing />,
    icon: <Icon fontSize="small">receipt_long</Icon>,
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN, ROLE_USER.USER],
      isPrivate: true,
      type: "collapse",
      key: "billing",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "Notifications",
    path: "/notifications",
    component: <Notifications />,
    icon: <Icon fontSize="small">notifications</Icon>,
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN, ROLE_USER.USER],
      isPrivate: true,
      type: "collapse",
      key: "notifications",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "Profile",
    path: "/profile",
    component: <Profile />,
    icon: <Icon fontSize="small">person</Icon>,
    meta: {
      role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN, ROLE_USER.USER],
      isPrivate: true,
      type: "collapse",
      key: "profile",
      isViewSideBar: false,
      child: false,
    },
  },
  // {
  // eslint-disable-next-line no-irregular-whitespace
  //   name: "案件情報集計​",
  //   path: "/statistic",
  //   component: <Statistic />,
  // eslint-disable-next-line no-irregular-whitespace
  //   breadCrumb: [{ label: "案件情報集計​", link: null }],
  //   icon: <Icon fontSize="small">multiline_chart</Icon>,
  //   meta: {
  //     role: [ROLE_USER.ADMIN, ROLE_USER.SUB_ADMIN],
  //     isPrivate: true,
  //     type: "collapse",
  //     key: "statistic",
  //     isViewSideBar: true,
  //     child: false,
  //   },
  // },
  {
    name: "LoginAdmin",
    path: "/admin",
    component: <LoginAdmin />,
    icon: <Icon fontSize="small">person</Icon>,
    meta: {
      role: ["*"],
      isPrivate: false,
      type: "collapse",
      key: "admin",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "ForgotPassword",
    path: "/forgot-password",
    component: <ForgotPassword />,
    icon: <Icon fontSize="small">person</Icon>,
    meta: {
      role: ["*"],
      isPrivate: false,
      type: "collapse",
      key: "forgot-password",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "SendEmail",
    path: "/send-email",
    component: <SendEmail />,
    icon: <Icon fontSize="small">person</Icon>,
    meta: {
      role: ["*"],
      isPrivate: false,
      type: "collapse",
      key: "send-email",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "ResetPassword",
    path: "/reset-password",
    component: <ResetPassword />,
    icon: <Icon fontSize="small">person</Icon>,
    meta: {
      role: ["*"],
      isPrivate: false,
      type: "collapse",
      key: "reset-password",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "Expired",
    path: "/expired",
    component: <Expired />,
    icon: <Icon fontSize="small">person</Icon>,
    meta: {
      role: ["*"],
      isPrivate: false,
      type: "collapse",
      key: "expired",
      isViewSideBar: false,
      child: false,
    },
  },
  // {
  //   name: "Register",
  //   path: "/register",
  //   component: <Register />,
  //   icon: <Icon fontSize="small">person</Icon>,
  //   meta: {
  //     role: ["*"],
  //     isPrivate: false,
  //     type: "collapse",
  //     key: "register",
  //     isViewSideBar: false,
  //     child: false,
  //   },
  // },
  {
    name: "LoginUser",
    path: "/login",
    component: <LoginUser />,
    icon: <Icon fontSize="small">person</Icon>,
    meta: {
      role: ["*"],
      isPrivate: false,
      type: "collapse",
      key: "login",
      isViewSideBar: false,
      child: false,
    },
  },
  // {
  //   name: "AuthGoogle",
  //   path: "/auth/google/callback",
  //   component: <AuthGoogle />,
  //   icon: <Icon fontSize="small">person</Icon>,
  //   meta: {
  //     role: ["*"],
  //     isPrivate: false,
  //     type: "collapse",
  //     key: "auth-google-callback",
  //     isViewSideBar: false,
  //     child: false,
  //   },
  // },
  // {
  //   name: "AuthTwitter",
  //   path: "/auth/twitter/callback",
  //   component: <AuthTwitter />,
  //   icon: <Icon fontSize="small">person</Icon>,
  //   meta: {
  //     role: ["*"],
  //     isPrivate: false,
  //     type: "collapse",
  //     key: "auth-twitter-callback",
  //     isViewSideBar: false,
  //     child: false,
  //   },
  // },
  {
    name: "AccidentInfomation",
    path: "/",
    component: <AccidentInfomation />,
    icon: <Icon fontSize="small">person</Icon>,
    meta: {
      role: ["*"],
      // isPrivate: false,
      special: true,
      type: "collapse",
      key: "AccidentInfomation",
      isViewSideBar: false,
      child: false,
    },
  },
  {
    name: "HandleRedirectGmail",
    path: "/redirect/:page/:id",
    component: <HandleRedirectGmail />,
    icon: <Icon fontSize="small">person</Icon>,
    meta: {
      role: ["*"],
      // isPrivate: false,
      special: true,
      type: "collapse",
      key: "HandleRedirectGmail",
      isViewSideBar: false,
      child: false,
    },
  },
];

const AUTH_PAGE_USER = "/login";
const AUTH_PAGE_ADMIN = "/admin";

export const getRoutes = (allRoutes, auth, dataUser) =>
  allRoutes.map((route) => {
    if (route.meta.child) {
      return getRoutes(route.meta.child);
    }
    if (route.meta?.special) {
      return <Route exact path={route.path} element={route.component} key={route.name} />;
    }
    if (route.meta.isPrivate) {
      const isRole = route.meta.role.includes(dataUser?.role);
      if (auth) {
        return isRole ? (
          <Route exact path={route.path} element={route.component} key={route.name} />
        ) : (
          <Route exact path={route.path} element={<Navigate to="/dashboard" />} key={route.name} />
        );
      }
      const historyRole = localStorage.getItem("Hsp_Role");
      return historyRole?.includes(ROLE_USER.USER) ? (
        <Route
          exact
          path={route.path}
          element={<Navigate to={AUTH_PAGE_USER} />}
          key={route.name}
        />
      ) : (
        <Route
          exact
          path={route.path}
          element={<Navigate to={AUTH_PAGE_ADMIN} />}
          key={route.name}
        />
      );
    }
    if (!route.meta.isPrivate) {
      if (auth) {
        return (
          <Route exact path={route.path} element={<Navigate to="/dashboard" />} key={route.name} />
        );
      }
      return <Route exact path={route.path} element={route.component} key={route.name} />;
    }
    return null;
  });

export default routes;
