import { useNavigate, useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import masterCardLogo from "assets/images/logos/logo.jpg";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { post } from "api/BaseRequest";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import MDSnackbar from "components/MDSnackbar";
import { ROLE_USER } from "constants/user";
import jwt_decode from "jwt-decode";
import InputController from "components/withController/InputController";
import { checkTopLevelDomailEmail, regexPassword, regexNameFullWidth } from "utils/regex";
import BasicLayout from "../components/BasicLayout";

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  useEffect(() => {
    if (token) {
      const { exp } = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (currentTime > exp) {
        navigate("/expired");
      }
    }
  }, [token]);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const putResetPassword = async (data) => post("api/reset-password", data);

  const { mutate: resetPassword, isLoading } = useMutation(putResetPassword, {
    onSuccess: (data) => {
      openSuccessSB();
      setTimeout(() => {
        const { email, role } = data.data;
        if (role === ROLE_USER.USER) {
          navigate("/login", {
            state: { email },
          });
        }
        if (role === ROLE_USER.ADMIN || role === ROLE_USER.SUB_ADMIN) {
          navigate("/admin", {
            state: { email },
          });
        }
      }, 1000);
    },
    onError: () => {
      openErrorSB();
    },
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("validate.E001")).matches(regexPassword, t("validate.E027")),
    // .matches(/^[a-zA-Z0-9]+$/, t("validate.E027"))
    // .min(8, t("validate.E027"))
    // .max(16, t("validate.E027")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("validate.password.match"))
      .required(t("validate.E001")),
  });
  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    document.title = t("title.resetPassword");
    return () => {
      document.title = t("title");
    };
  }, []);

  const onReset = (data) => {
    resetPassword({
      password: data.password,
      token,
    });
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox variant="gradient" borderRadius="lg" mx={2} mt={-4} textAlign="center">
          <MDBox
            component="img"
            src={masterCardLogo}
            alt="master card"
            width="100%"
            borderRadius="lg"
            coloredShadow="info"
          />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <InputController
                name="password"
                control={control}
                type="password"
                label={t("lable.newPassword")}
                textRequired="*"
              />
              <InputController
                name="confirmPassword"
                control={control}
                type="password"
                label={t("lable.newPassword.confirm")}
                textRequired="*"
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit(onReset)}
                disabled={isLoading}
              >
                {t("resetPassword.update")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("resetPassword.success")}
        // dateTime="11 mins ago"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("change.password.failed")}
        // dateTime="11 mins ago"
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </BasicLayout>
  );
}

export default ResetPassword;
