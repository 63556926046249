import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { put, get } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import { ROLE_USER, ORGANIZATION, POST_CODE_LENGTH } from "constants/user";
import useUser from "hooks/useUser";
import InputController from "components/withController/InputController";
import {
  checkTopLevelDomailEmail,
  regexNameFullWidth,
  regexPassword,
  regexPostCode,
  regexPhoneNumber,
} from "utils/regex";
import InputRadioController from "components/withController/InputRadioController";

function DetailUser() {
  const { t } = useTranslation();
  const { data } = useUser();
  const navigate = useNavigate();
  const params = useParams();

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().when("organization", {
      is: (value) => value === ORGANIZATION.INDIVIDUAL,
      then: Yup.string()
        .required(t("validate.E001"))
        .matches(regexNameFullWidth, t("validate.E003"))
        .max(50, t("validate.E003")),
    }),
    companyName: Yup.string().when("organization", {
      is: (value) => value === ORGANIZATION.LEGAL_ENTITY,
      then: Yup.string()
        .required(t("validate.E001"))
        .matches(regexNameFullWidth, t("validate.E003"))
        .max(50, t("validate.E003")),
    }),
    contractName: Yup.string()
      .test("", t("validate.E003"), (value) => {
        if (!value) {
          return true;
        }
        if (!value.trim().length) {
          return false;
        }
        return regexNameFullWidth.test(value);
      })
      .max(50, t("validate.E003")),
    phone: Yup.string().required(t("validate.E001")).matches(regexPhoneNumber, t("validate.E005")),
    email: Yup.string()
      .required(t("validate.E001"))
      .matches(/^[ -~]+$/, t("validate.E007"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
    password: Yup.string().test("Function check password", t("validate.E027"), (value) => {
      if (!value) {
        return true;
      }
      return regexPassword.test(value);
    }),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("validate.password.match"))
      .when("password", {
        is: (value) => value,
        then: Yup.string().required(t("validate.E001")),
      }),
    postCode: Yup.string().test("", t("validate.E009"), (value) => {
      if (!value) {
        return true;
      }
      return regexPostCode.test(value);
    }),
    address: Yup.string().max(100, t("validate.E014")),
    industry: Yup.string().max(255, t("validate.E015")),
    constructionType: Yup.string().max(255, t("validate.E015")),
    salesRepresentative: Yup.string()
      .required(t("validate.E001"))
      .matches(regexNameFullWidth, t("validate.E003"))
      .max(50, t("validate.E003")),
    remarks: Yup.string().max(255, t("validate.E015")),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      fullName: "",
      companyName: "",
      phone: "",
      postCode: "",
      address: "",
      industry: "",
      constructionType: "",
      salesRepresentative: "",
      remarks: "",
      organization: ORGANIZATION.LEGAL_ENTITY,
      contractName: "",
      idUser: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const getDetailUser = async () => get(`api/users/${params.id}`);
  const putUser = async (data) => put(`api/users/${params.id}`, data);

  const { data: dataUser } = useQuery(["getDetailUser"], getDetailUser, {
    keepPreviousData: true,
  });

  const { mutate: updateUser, isLoading } = useMutation(putUser, {
    onSuccess: () => {
      openSuccessSB();
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.detailUser");
    return () => {
      document.title = t("title");
    };
  }, []);

  useEffect(() => {
    if (dataUser) {
      const { data } = dataUser;
      reset({
        ...data,
        organization: data.organization.toString(),
        idUser: data.id,
        fullName: data.fullName || "",
        companyName: data.companyName || "",
        contractName: data.contractName || "",
        industry: data.industry || "",
        postCode: data.postCode || "",
        address: data.address || "",
        constructionType: data.constructionType || "",
        remarks: data.remarks || "",
        password: "",
      });
    }
  }, [dataUser]);

  useEffect(async () => {
    try {
      if (watch("postCode")?.length === POST_CODE_LENGTH) {
        const response = await fetch(
          `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${watch("postCode")}`
        );
        const result = await response.json();
        if (result.results === null) {
          setValue("address", "");
          setError("postCode", { type: "custom", message: t("validate.E032") });
        }
        if (result.results !== null) {
          setValue("address", `${result.results[0]?.address1}－${result.results[0]?.address2}`);
        }
      } else {
        setValue("address", "");
      }
    } catch (error) {
      setError("postCode", { type: "custom", message: t("validate.E032") });
    }
  }, [watch("postCode")]);

  const onUpdateUser = (data) => {
    const newData = {
      ...data,
      email: data.email.trim(),
      fullName: data.organization === ORGANIZATION.INDIVIDUAL ? data.fullName.trim() : null,
      companyName: data.organization === ORGANIZATION.LEGAL_ENTITY ? data.companyName.trim() : null,
      contractName: data.contractName?.trim() || null,
      postCode: data.postCode || null,
      address: data.address || null,
      industry: data.industry || null,
      constructionType: data.constructionType || null,
      salesRepresentative: data.salesRepresentative.trim(),
      remarks: data.remarks || null,
      password: data.password || null,
    };
    updateUser(newData);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {t("listAken.btn.detail")}
            </MDTypography>
          </MDBox>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={2} pb={1}>
              <InputRadioController
                control={control}
                name="organization"
                option={[
                  {
                    label: t("detailUserForAnkenCompanyNameText"),
                    value: ORGANIZATION.LEGAL_ENTITY,
                  },
                  { label: t("detailUserForAnkenUserNameText"), value: ORGANIZATION.INDIVIDUAL },
                ]}
                disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MDBox pt={0} px={3}>
                <MDBox component="form">
                  <InputController
                    name="idUser"
                    control={control}
                    type=""
                    label={`${t("lable.userId")}`}
                    textRequired=""
                    disabled
                  />
                  {watch("organization") === ORGANIZATION.INDIVIDUAL && (
                    <InputController
                      name="fullName"
                      control={control}
                      type="text"
                      label={t("lable.name")}
                      textRequired="*"
                      disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                      tabIndexDisable={data?.role === ROLE_USER.SUB_ADMIN}
                    />
                  )}
                  {watch("organization") === ORGANIZATION.LEGAL_ENTITY && (
                    <InputController
                      name="companyName"
                      control={control}
                      type="text"
                      label={t("lable.companyName")}
                      textRequired="*"
                      disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                    />
                  )}
                  <InputController
                    name="contractName"
                    control={control}
                    type="text"
                    label={t("lable.nameOfPersonCharge")}
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                  />
                  <InputController
                    name="phone"
                    control={control}
                    type="tel"
                    label={t("lable.phoneNumber")}
                    textRequired="*"
                    isAutoFormatPhoneNumber
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                  />
                  <InputController
                    name="email"
                    control={control}
                    type="email"
                    label={t("lable.email")}
                    disabled
                    textRequired="*"
                    tabIndexDisable={data?.role === ROLE_USER.SUB_ADMIN}
                  />
                  <InputController
                    name="password"
                    control={control}
                    type="password"
                    label={t("lable.password")}
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                    tabIndexDisable={data?.role === ROLE_USER.SUB_ADMIN}
                  />
                  <InputController
                    name="confirmPassword"
                    control={control}
                    type="password"
                    label={t("lable.password.confirm")}
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                    tabIndexDisable={data?.role === ROLE_USER.SUB_ADMIN}
                  />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MDBox pt={0} px={3}>
                <MDBox component="form">
                  <InputController
                    name="postCode"
                    control={control}
                    type="text"
                    label={t("lable.postCode")}
                    textRequired=""
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                  />
                  <InputController
                    name="address"
                    control={control}
                    type="text"
                    label={t("lable.userAddress")}
                    textRequired=""
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                  />
                  <InputController
                    name="industry"
                    control={control}
                    type="text"
                    label={t("lable.industry")}
                    textRequired=""
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                  />
                  {/* <InputController
                    name="constructionType"
                    control={control}
                    type="text"
                    label={t("lable.constructionType")}
                    textRequired=""
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                  /> */}
                  <InputController
                    name="salesRepresentative"
                    control={control}
                    type="text"
                    label={t("lable.salesRepresentative")}
                    textRequired="*"
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                  />
                  <InputController
                    name="remarks"
                    control={control}
                    type="text"
                    label={t("lable.note")}
                    multiline
                    rows={4}
                    maxRows={4}
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                  />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={0} mt={1} pb={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MDBox mt={2} mb={1} px={3}>
                <MDButton variant="outlined" color="info" fullWidth onClick={() => navigate(-1)}>
                  {t("detailUser.btn.back")}
                </MDButton>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MDBox mt={2} mb={1} px={3}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSubmit(onUpdateUser)}
                  disabled={isLoading || data?.role === ROLE_USER.SUB_ADMIN}
                >
                  {t("detailUser.btn.update")}
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Footer />
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("createUser.register.success")}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("createUser.register.failed")}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </DashboardLayout>
  );
}

export default DetailUser;
