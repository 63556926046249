import { RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import MDBox from "components/MDBox";
import { Controller } from "react-hook-form";

function InputRadioController({ control, name, option = [], disabled = false }) {
  return (
    <MDBox px={3} pt={0} component="form">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <RadioGroup row onChange={onChange} name={name} control={control} value={value}>
            {option.map((item) => (
              <FormControlLabel
                value={item.value}
                control={<Radio />}
                label={item.label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        )}
      />
    </MDBox>
  );
}
export default InputRadioController;
