import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Controller } from "react-hook-form";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { jaJP } from "@mui/x-date-pickers/locales";
import "moment/locale/ja";

function TimePickerController({ name, control, label }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MDBox mb={2}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="ja"
            localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            <MobileTimePicker
              label={label}
              views={["hours", "minutes"]}
              format="HH:mm"
              sx={{
                width: "100%",
                "& .MuiInputLabel-root": {
                  paddingTop: "2px",
                },
              }}
              onChange={onChange}
              value={value}
            />
          </LocalizationProvider>
          {error?.message && (
            <MDTypography variant="caption" color="error">
              {error?.message}
            </MDTypography>
          )}
        </MDBox>
      )}
    />
  );
}
export default TimePickerController;
