import MDBox from "components/MDBox";
import { Dialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DialogConfirm = ({ open, onClose, renderDialogContent, renderDialogActions }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="xs"
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
      <IconButton onClick={onClose} color="info" aria-label="close">
        <CloseIcon />
      </IconButton>
    </MDBox>
    <DialogContent>{renderDialogContent()}</DialogContent>
    <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
      {renderDialogActions()}
    </DialogActions>
  </Dialog>
);
export default DialogConfirm;
