import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { CircularProgress, Pagination, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  paginationInfo = {},
  params,
  setParams,
  isLoading,
}) {
  const { t } = useTranslation();
  const { totalPages, currentPage, perPage } = paginationInfo;
  const defaultValue = +perPage || 10;
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    // pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // console.log("pageOptions", pageOptions);
  const [pageOptions, setPageOptions] = useState([]);

  useEffect(() => {
    if (!data?.length && currentPage > 1) {
      setParams({ ...params, page: currentPage - 1 });
    }
  }, [data]);

  // Handler for the input to set the pagination index

  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table {...getTableProps()} sx={{ width: "100%", tableLayout: "auto" }}>
        {isLoading ? (
          <MDBox sx={{ display: "flex", justifyContent: "center", paddingBottom: "18px" }}>
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <>
            {Boolean(data?.length) && (
              <>
                <MDBox component="thead">
                  {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <DataTableHeadCell
                          {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                          width={column.width ? column.width : "auto"}
                          align={column.align ? column.align : "left"}
                          sorted={setSortedValue(column)}
                          headerWidth={column.headerWidth}
                        >
                          {column.render("Header")}
                        </DataTableHeadCell>
                      ))}
                    </TableRow>
                  ))}
                </MDBox>
                <TableBody {...getTableBodyProps()}>
                  {page.map((row, key) => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()} sx={{ padding: "0px" }}>
                        {row.cells.map((cell) => (
                          <DataTableBodyCell
                            noBorder={noEndBorder && rows.length - 1 === key}
                            align={cell.column.align ? cell.column.align : "left"}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </DataTableBodyCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </>
            )}
            {Boolean(!data?.length) && (
              <MDBox sx={{ display: "flex", justifyContent: "center", padding: "18px" }}>
                <MDTypography variant="body2" color="text" alignItems="center">
                  {t("text.noData")}
                </MDTypography>
              </MDBox>
            )}
          </>
        )}
      </Table>
      {totalPages > 1 && (
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="flex-end"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          <Stack spacing={2}>
            <Pagination
              shape="rounded"
              page={currentPage}
              count={totalPages}
              color="info"
              onChange={(e, value) => setParams({ ...params, page: value })}
            />
          </Stack>
        </MDBox>
      )}
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
