import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

function CustomerInformation({ exportPDF, dataAnken }) {
  const [loadingExportPDF, setLoadingExportPDF] = useState(false);
  const { t } = useTranslation();
  return (
    <Card style={{ minHeight: "200px" }}>
      <MDBox
        mx={2}
        mt={-3}
        py={2}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ minHeight: "70px" }}
      >
        <MDTypography variant="h6" color="white">
          {t("detailUserForAnken.title")}
        </MDTypography>
      </MDBox>
      {/* company or name */}
      <MDBox display="flex" py={1} px={3} pt={4}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("detailUserForAnken.companyNameOrName")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;
            {dataAnken?.data?.user?.companyName || dataAnken?.data?.user?.fullName}
          </MDTypography>
        </MDTypography>
      </MDBox>
      {/* sales */}
      <MDBox display="flex" py={1} px={3} pt={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {t("lable.salesRepresentative")}:
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{dataAnken?.data?.user?.salesRepresentative}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default CustomerInformation;
