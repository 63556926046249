export const OCCURRENCE_TYPE = {
  CARS: 1,
  COMPENSATIONS: 2,
  INJURIES: 3,
  BUILDINGS: 4,
  OTHERS: 5,
};
export const TEXT_OCCURRENCE_TYPE = {
  1: "自動車事故",
  2: "賠償事故・建築瑕疵",
  3: "ケガ・疾病",
  4: "建物または設備・什器の事故",
  5: "その他事故",
};
export const TEXT_INSURANCE_TYPE = {
  1: "自動車保険",
  2: "賠償責任保険",
  3: "傷害保険",
  4: "疾病保険",
  5: "動産総合保険",
  6: "取引信用保険",
  7: "建設工事保険",
  8: "火災保険",
  9: "E&O保険",
  10: "その他",
};
export const TEXT_BU_ACCIDENT_TYPE = {
  1: "漏水事故",
  2: "破損事故",
  3: "その他",
};

export const TEXT_STATUS = {
  1: "保険会社対応中",
  2: "弁護士対応中",
  3: "査定中",
  4: "査定完了",
  5: "解決",
  6: "請求なし",
};

export const ACCIDENT_TYPES = [
  { id: 0, name: "全て" },
  { id: 1, name: TEXT_OCCURRENCE_TYPE[1] },
  { id: 2, name: TEXT_OCCURRENCE_TYPE[2] },
  { id: 3, name: TEXT_OCCURRENCE_TYPE[3] },
  { id: 4, name: TEXT_OCCURRENCE_TYPE[4] },
  { id: 5, name: TEXT_OCCURRENCE_TYPE[5] },
];

export const INSURANCE_TYPES = [
  { id: 0, name: "全て" },
  { id: 1, name: TEXT_INSURANCE_TYPE[1] },
  { id: 2, name: TEXT_INSURANCE_TYPE[2] },
  { id: 3, name: TEXT_INSURANCE_TYPE[3] },
  { id: 4, name: TEXT_INSURANCE_TYPE[4] },
  { id: 5, name: TEXT_INSURANCE_TYPE[5] },
  { id: 6, name: TEXT_INSURANCE_TYPE[6] },
  { id: 7, name: TEXT_INSURANCE_TYPE[7] },
  { id: 8, name: TEXT_INSURANCE_TYPE[8] },
  { id: 9, name: TEXT_INSURANCE_TYPE[9] },
  { id: 10, name: TEXT_INSURANCE_TYPE[10] },
];

export const STATUS_TYPES = [
  { id: 0, name: "全て" },
  { id: 1, name: TEXT_STATUS[1] },
  { id: 2, name: TEXT_STATUS[2] },
  { id: 3, name: TEXT_STATUS[3] },
  { id: 4, name: TEXT_STATUS[4] },
  { id: 5, name: TEXT_STATUS[5] },
  { id: 6, name: TEXT_STATUS[6] },
];
