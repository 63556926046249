import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Controller } from "react-hook-form";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "moment/locale/ja";
import moment from "moment";
import { jaJP } from "@mui/x-date-pickers/locales";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

function DateRangePickerController({
  nameStart,
  lableStart = "開始日",
  nameEnd,
  lableEnd = "終了日",
  control,
  watch,
  disabled = false,
  grid = { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 },
  setErrorDate,
}) {
  const { t } = useTranslation();
  const [messageErrStart, setMessageErrStart] = useState("");
  const [messageErrEnd, setMessageErrEnd] = useState("");
  useEffect(() => {
    if (!setErrorDate) {
      return;
    }

    if (messageErrStart || messageErrEnd) {
      setErrorDate(true);
    } else {
      setErrorDate(false);
    }
  }, [messageErrStart, messageErrEnd]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg} xl={grid.xl}>
        <Controller
          control={control}
          name={nameStart}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <MDBox mb={2}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="ja"
                localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <DatePicker
                  slotProps={{
                    toolbar: {
                      toolbarFormat: "MM月 DD日",
                    },
                  }}
                  localeText={{
                    toolbarTitle: t("common.datePicker.toolbarTitle"),
                    fieldDayPlaceholder: () => "dd",
                    fieldMonthPlaceholder: () => "mm",
                    fieldYearPlaceholder: () => "yyyy",
                  }}
                  onError={(newError) => {
                    if (newError === "maxDate") {
                      if (value > moment()) {
                        setMessageErrStart("未来の時刻は入力できません。");
                      } else {
                        setMessageErrStart("終了日より前の開始日を選択してください");
                      }
                    } else {
                      setMessageErrStart("");
                    }
                  }}
                  label={lableStart}
                  onChange={onChange}
                  value={value}
                  sx={{
                    width: "100%",
                    "& .MuiInputLabel-root": {
                      paddingTop: "2px",
                    },
                  }}
                  maxDate={watch(nameEnd) || moment()}
                  disabled={disabled}
                />
              </LocalizationProvider>
              {messageErrStart && (
                <MDTypography variant="caption" color="error">
                  {messageErrStart}
                </MDTypography>
              )}
            </MDBox>
          )}
        />
      </Grid>
      <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg} xl={grid.xl}>
        <Controller
          control={control}
          name={nameEnd}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <MDBox mb={2}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="ja"
                localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <DatePicker
                  slotProps={{
                    toolbar: {
                      toolbarFormat: "MM月 DD日",
                    },
                  }}
                  localeText={{
                    toolbarTitle: t("common.datePicker.toolbarTitle"),
                    fieldDayPlaceholder: () => "dd",
                    fieldMonthPlaceholder: () => "mm",
                    fieldYearPlaceholder: () => "yyyy",
                  }}
                  onError={(newError) => {
                    if (newError === "maxDate") {
                      setMessageErrEnd("未来の時刻は入力できません。");
                      return;
                    }
                    if (newError === "minDate") {
                      setMessageErrEnd("終了日より前の開始日を選択してください");
                      return;
                    }
                    setMessageErrEnd("");
                  }}
                  label={lableEnd}
                  onChange={onChange}
                  value={value}
                  sx={{
                    width: "100%",
                    "& .MuiInputLabel-root": {
                      paddingTop: "2px",
                    },
                  }}
                  minDate={watch(nameStart)}
                  maxDate={moment()}
                  disabled={disabled}
                />
              </LocalizationProvider>
              {messageErrEnd && (
                <MDTypography variant="caption" color="error">
                  {messageErrEnd}
                </MDTypography>
              )}
            </MDBox>
          )}
        />
      </Grid>
    </Grid>
  );
}
export default DateRangePickerController;
